
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /* eslint-disable react/no-danger */
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import FramerProvider from '@/lib/providers/FramerProvider';
import ThemeProvider from '@/lib/providers/ThemeProvider';
import MainLayout from '@/components/core/MainLayout';
import { CustomAppProps } from '@/interfaces/pages/app.interface';
import '@/styles/theme/globals.scss';
import TanstackQueryProvider from '@/lib/providers/TanstackQueryProvider';
import { HydrationBoundary } from '@tanstack/react-query';
import Seo from '@/components/core/Seo';
import { CasinoMetadata } from '@/components/core/Seo/props';
import dynamic from 'next/dynamic';
import { platformAssets } from '@/utils/assets';
import Head from 'next/head';
import { wrapper } from '@/lib/store';
import { setSettings } from '@/lib/store/theme/actions';
import PackageCommonSettingsProvider from '@/lib/providers/PackageCommonSettingsProvider';
const NotificationToasty = dynamic(() => import('@/components/Notifications/NotificationToasty'), { ssr: false });
function App({ Component, ...rest }: CustomAppProps) {
    const { store, props } = wrapper.useWrappedStore(rest);
    if (props?.pageProps?.settings) {
        store.dispatch(setSettings(props?.pageProps?.settings));
    }
    return (<main>
      {platformAssets.tracking?.GTM?.[1] && (<Head>
          <script dangerouslySetInnerHTML={{
                __html: `!function(){"use strict";function l(e){for(var t=e,r=0,n=document.cookie.split(";");r<n.length;r++){var o=n[r].split("=");if(o[0].trim()===t)return o[1]}}function s(e){return localStorage.getItem(e)}function u(e){return window[e]}function d(e,t){e=document.querySelector(e);return t?null==e?void 0:e.getAttribute(t):null==e?void 0:e.textContent}var e=window,t=document,r="script",n="dataLayer",o="${platformAssets.tracking.GTM[1].replace('GTM-', '')}",a="https://gtm.ice.game",i="",c="cgeexgoj",E="stapeUserId",I="",v="",g=!1;try{var g=!!E&&(m=navigator.userAgent,!!(m=new RegExp("Version/([0-9._]+)(.Mobile)?.*Safari.").exec(m)))&&16.4<=parseFloat(m[1]),A="stapeUserId"===E,f=g&&!A?function(e,t,r){void 0===t&&(t="");var n={cookie:l,localStorage:s,jsVariable:u,cssSelector:d},t=Array.isArray(t)?t:[t];if(e&&n[e])for(var o=n[e],a=0,i=t;a<i.length;a++){var c=i[a],c=r?o(c,r):o(c);if(c)return c}else console.warn("invalid uid source",e)}(E,I,v):void 0;g=g&&(!!f||A)}catch(e){console.error(e)}var m=e,E=(m[n]=m[n]||[],m[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"}),t.getElementsByTagName(r)[0]),I="dataLayer"===n?"":"&l="+n,v=f?"&bi="+encodeURIComponent(f):"",A=t.createElement(r),e=g?"kp"+c:c,n=!g&&i?i:a;A.async=!0,A.src=n+"/"+e+".js?st="+o+I+v+"",null!=(f=E.parentNode)&&f.insertBefore(A,E)}();`,
            }}/>
        </Head>)}
      <FramerProvider>
        <TanstackQueryProvider>
          <PackageCommonSettingsProvider>
            <HydrationBoundary state={props?.pageProps?.dehydratedState}>
              <ThemeProvider>
                <CssBaseline />
                {!!props?.pageProps?.metadata && (<Seo metadata={props?.pageProps?.metadata as CasinoMetadata}/>)}
                <NotificationToasty />
                <MainLayout setting={props?.pageProps?.settings}>
                  <Component {...props?.pageProps} suppressHydrationWarning/>
                </MainLayout>
              </ThemeProvider>
            </HydrationBoundary>
          </PackageCommonSettingsProvider>
        </TanstackQueryProvider>
      </FramerProvider>
    </main>);
}
const __Next_Translate__Page__19331aeb712__ = wrapper.withRedux(App);

    export default __appWithI18n(__Next_Translate__Page__19331aeb712__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  